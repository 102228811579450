import React, { useState, useEffect } from "react";
import axios from "axios";
// import Cookies from "js-cookie";
import { TESA_HOST, HOST_NAME } from "../const";
import { useParams } from "react-router-dom";
import "./Tesa.css";

const Tesa = () => {
  const [reply, setReply] = useState("");
  // const [token, setToken] = useState("");
  // const [uuid, setUUID] = useState("");

  const { lastSegment } = useParams();

  const [chat, setChat] = useState([]);

  const sendReply = () => {
    setChat((prevChat) => [...prevChat, { user: reply }]);
    setReply("");
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      await axios
        .post(`${TESA_HOST}/report/post-chat`, {
          questionName: reply,
          employee: lastSegment,
          // isEnd: isEnd,
          // prvData: chat,
        })
        .then((res) => {
          setChat((prevChat) => [
            ...prevChat,
            {
              user: reply,
              bot: res.data.data.questionResponseCreated.response,
            },
          ]);
          setReply("");
        })
        .catch((e) => console.log(e));
      // setChat((prevChat) => [...prevChat, { user: reply }]);
    }
  };

  useEffect(() => {
    // const path = window.location.pathname;
    // const lastSegment = path.split("/").filter(Boolean).pop();
    // setUUID(lastSegment);
    const updateCount = async () => {
      await axios
        .post(`${HOST_NAME}/report/update-tesaCount`, {
          uuid: lastSegment,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    };
    updateCount();
  }, []);

  return (
    <div className="w-full h-full flex justify-center content-center">
      {/* <iframe
        title="Insight Viewer"
        src="https://curation.aspirenow.in"
        style={{ width: "80vw", height: "90vh" }}
        frameBorder="0"
        allowFullScreen
      ></iframe> */}
      <div
        style={{
          boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)",
        }}
        className=" md:mt-7 bg-[rgba(255,255,255,0.4)] p-6 rounded-lg border border-[#e5e7eb] w-full h-full mb-9 md:w-9/12 md:h-[90%]"
      >
        <div className="flex flex-col space-y-1.5 pb-6">
          <h2 className="font-semibold text-lg tracking-tight">
            Transforming Everyday Simple Actions
          </h2>
          <p className="text-sm text-[#6b7280] leading-3">
            Powered by <span className="font-bold">Aspire</span>
          </p>
        </div>

        <div
          id="scroll-container"
          className="pr-4 h-[474px]"
          style={{
            minWidth: "100%",
            maxHeight: "450px",
            overflowY: "scroll",
          }}
        >
          <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <svg
                  stroke="none"
                  fill="black"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  ></path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">TESA </span>
              Hello I am Tesa!, I can help you to curate habits for you. Take a
              minute to chat with me ❤️
            </p>
          </div>
          {chat.map((ch) => (
            <div>
              <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
                <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                  <div class="rounded-full bg-gray-100 border p-1">
                    <svg
                      stroke="none"
                      fill="black"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                    </svg>
                  </div>
                </span>
                <p class="leading-relaxed">
                  <span class="block font-bold text-gray-700">You </span>
                  {ch.user}
                </p>
              </div>
              {ch.bot !== undefined && (
                <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
                  <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                    <div class="rounded-full bg-gray-100 border p-1">
                      <svg
                        stroke="none"
                        fill="black"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                        ></path>
                      </svg>
                    </div>
                  </span>
                  <p class="leading-relaxed">
                    <span class="block font-bold text-gray-700">TESA </span>
                    {ch.bot}
                  </p>
                </div>
              )}
            </div>
          ))}

          {/* <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <svg
                  stroke="none"
                  fill="black"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  ></path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">AI </span>Sorry, I
              couldn't find any information in the documentation about that.
              Expect answer to be less accurateI could not find the answer to
              this in the verified sources.
            </p>
          </div>
          <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <svg
                  stroke="none"
                  fill="black"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  ></path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">AI </span>Sorry, I
              couldn't find any information in the documentation about that.
              Expect answer to be less accurateI could not find the answer to
              this in the verified sources.
            </p>
          </div>
          <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <svg
                  stroke="none"
                  fill="black"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  ></path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">AI </span>Sorry, I
              couldn't find any information in the documentation about that.
              Expect answer to be less accurateI could not find the answer to
              this in the verified sources.
            </p>
          </div> */}
        </div>
        <div class="flex items-center pt-0 mb-7">
          <div class="flex items-center justify-center w-full space-x-2">
            <input
              type="text"
              value={reply}
              class="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
              placeholder="Type your message"
              onChange={(e) => setReply(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              class="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb]  bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
              onClick={() => sendReply()}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tesa;
