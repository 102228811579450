const initiativeCont_Genesis = [
  {
    "Physiological Health": [
      {
        Score: "Good",
        Trivia:
          "Did you know? Advanced fitness routines can enhance cognitive function and reduce stress, making you up to 20% more productive at work! Physical fitness and mental sharpness go hand in hand.",
        Apps: [
          {
            data: "Strava: Perfect for tracking more advanced fitness activities like running, cycling, or swimming and joining challenges to push yourself further.",
          },
          {
            data: "MyFitnessPal: For detailed tracking of your nutrition and macros, helping you fine-tune your diet for peak performance.",
          },
          {
            data: "Cure.fit (Cult.fit): Offers a wide variety of high-intensity workout classes, yoga, and strength training sessions to maintain and enhance your fitness levels.",
          },
        ],
        Hints: [
          {
            data: "Push Your Limits: Incorporate more challenging physical activities like high-intensity interval training (HIIT), endurance sports, or weightlifting to continue improving fitness.",
          },
          {
            data: "Optimize Recovery: Focus on improving sleep quality and nutrition to ensure your body recovers well. Try adding more nutrient-dense foods and optimizing your sleep schedule for better recovery.",
          },
          {
            data: "Track Performance: Use fitness trackers to monitor progress over time. Aim for new personal bests in your workouts, such as increasing running distance, lifting heavier weights, or improving flexibility.",
          },
        ],
        Books:
          '"Atomic Habits" by James Clear – A practical guide on building and sustaining healthy habits, making it easier to maintain long-term success in nutrition, exercise, and rest.',
        Podcasts:
          '"The Model Health Show" – Hosted by Shawn Stevenson, this podcast covers a variety of topics on nutrition, fitness, and well-being, offering actionable advice for maintaining a healthy',
      },
      {
        Score: "Average",
        Trivia:
          "Did you know? Just 15 minutes of moderate exercise a day can increase your life expectancy by up to 3 years! Small changes in your daily routine can have long-lasting health benefits.",
        Apps: [
          {
            data: "FitOn: Offers free workout plans, including cardio, strength, and yoga sessions, helping you gradually improve your fitness level.",
          },
          {
            data: "7 Minute Workout: A quick and effective app that allows you to incorporate short bursts of exercise into your daily routine, perfect for busy schedules.",
          },
          {
            data: "WaterMinder: An app that helps track your daily water intake, ensuring proper hydration, which is essential for overall health and energy levels.",
          },
        ],
        Hints: [
          {
            data: "Consistency is Key: Focus on establishing a regular exercise routine, even if it’s just 15-20 minutes a day. Start small and build gradually to avoid burnout.",
          },
          {
            data: "Hydration First: Drink plenty of water throughout the day. Staying hydrated is one of the easiest ways to improve energy levels, focus, and overall well-being.",
          },
          {
            data: "Incorporate Movement into Work: Take short walking breaks or stretch throughout the workday to keep your body moving and reduce strain from sitting too long.",
          },
        ],
        Books:
          '"Feel Better in 5" by Dr. Rangan Chatterjee – This book provides simple, five-minute daily activities that can improve your physical and mental well-being.',
        Podcasts:
          '"The Mind Pump Podcast" – A fitness-focused podcast that provides practical advice on exercise, nutrition, and overall health. It’s perfect for someone looking to tweak and improve their routine.',
      },
      {
        Score: "Bad",
        Trivia:
          "Did you know? Even just 10 minutes of walking each day can reduce the risk of heart disease and boost your mood. Every small step adds up to big health benefits over time!",
        Apps: [
          {
            data: "Pacer: A simple app that tracks your steps and encourages you to walk more, perfect for building a habit of daily movement.",
          },
          {
            data: "Simple Habit Tracker: Helps you develop healthy habits, like drinking more water or getting enough sleep, with easy-to-follow goals.",
          },
          {
            data: "Sleep Cycle: Monitors your sleep patterns and provides insights on how to improve the quality of your rest, crucial for overall health improvement.",
          },
        ],
        Hints: [
          {
            data: "Start Small with Movement: Begin with light exercises like a 10-15 minute walk each day. Focus on gradually increasing your activity level rather than jumping into intense workouts.",
          },
          {
            data: "Prioritize Sleep: Aim for at least 7-8 hours of sleep each night. Create a bedtime routine that helps you wind down and improve sleep quality.",
          },
          {
            data: "Basic Nutrition Improvements: Start by cutting down on processed foods and sugary drinks. Replace them with more fruits, vegetables, and whole grains to boost energy and well-being.",
          },
        ],
        Books:
          "Tiny Habits by BJ Fogg – This book teaches how to start with small, manageable changes that can lead to bigger transformations over time, perfect for someone looking to make small but impactful improvements.",
        Podcasts:
          "Feel Better, Live More with Dr. Rangan Chatterjee – Offers practical advice and conversations about improving health, making small changes to diet, exercise, and mindset, ideal for someone needing motivational tips to start.",
      },
    ],
  },
  {
    "Spiritual Health": [
      {
        Score: "Good",
        Trivia:
          "Did you know? People who mentor others often experience a 20% boost in their own job satisfaction and purpose, as they help others achieve meaningful growth.",
        Apps: [
          {
            data: "Coach.me: A goal-setting and habit-building app that helps you stay aligned with your purpose while also mentoring others on their journey.",
          },
          {
            data: "Asana: Perfect for managing purpose-driven projects, allowing you to track progress on initiatives that align with your values and long-term goals.",
          },
          {
            data: "LinkedIn Learning: Offers courses on leadership, personal growth, and purpose-driven work, helping you take your spiritual and career alignment to the next level.",
          },
        ],
        Hints: [
          {
            data: "Mentor Others: Use your experience to mentor or coach colleagues, helping them find their own sense of purpose and alignment with their career. Guiding others can reinforce your own sense of purpose.",
          },
          {
            data: "Lead Purpose-Driven Initiatives: Take charge of projects that align with company values and your personal mission, whether it's community involvement, CSR activities, or internal well-being programs.",
          },
          {
            data: "Reflect Regularly: Set aside time weekly or monthly to reflect on your achievements and how they contribute to your life’s purpose. Adjust your goals to ensure they are still aligned with what matters most to you.",
          },
        ],
        Books:
          '"The Alchemist" by Paulo Coelho – A beautiful story about following your dreams and living a life of purpose. It’s inspiring for anyone on a journey of self-discovery and fulfillment.',
        Podcasts:
          '"The School of Greatness" with Lewis Howes – This podcast features inspiring stories from people who have found their purpose and made a meaningful impact in their communities and industries.',
      },
      {
        Score: "Average",
        Trivia:
          "Did you know? Employees who participate in projects that align with their personal values report a 30% increase in job satisfaction and engagement, making even routine work feel more meaningful.",
        Apps: [
          {
            data: "Notion: Ideal for organizing your career goals, vision boards, and tracking tasks that align with your personal and professional values.",
          },
          {
            data: "Trello: Helps you manage and prioritize projects that are meaningful to you, ensuring your daily tasks align with your bigger life and career goals.",
          },
          {
            data: "Headspace for Work: Although primarily known for mindfulness, it offers tools for career reflection and aligning your work with your personal purpose.",
          },
        ],
        Hints: [
          {
            data: "Clarify Your Purpose: Spend time identifying the aspects of your job that resonate with your personal values. Reflect on how your work can align with your long-term aspirations, and adjust your approach accordingly.",
          },
          {
            data: "Join Meaningful Projects: Volunteer for projects or initiatives within the company that match your interests and values, such as sustainability efforts, wellness programs, or diversity initiatives.",
          },
          {
            data: "Seek Growth Opportunities: Find ways to grow by taking on new responsibilities or roles that challenge you and bring more purpose to your career. Even small adjustments in your role can provide more fulfillment.",
          },
        ],
        Books:
          '"Start with Why" by Simon Sinek: This book dives into the concept of leading with purpose and understanding the "why" behind what you do. It\'s a great resource for anyone looking to connect their work to a larger mission.',
        Podcasts:
          '"On Purpose with Jay Shetty": This podcast focuses on finding your purpose and connecting your daily actions to a larger mission. It features insights and conversations on living a life of meaning and purpose.',
      },
      {
        Score: "Bad",
        Trivia:
          "Did you know? Employees who take just 5 minutes each day to reflect on their personal and professional goals are more likely to feel a stronger sense of purpose and satisfaction in their work.",
        Apps: [
          {
            data: "Daylio: A simple journaling app that helps track your mood and activities, allowing you to reflect on what brings meaning and fulfillment to your day.",
          },
          {
            data: "Penzu: A digital diary for reflecting on your values, work experiences, and identifying small ways to align your daily activities with your larger purpose.",
          },
          {
            data: "Todoist: Helps in setting small, achievable goals that can slowly build towards aligning your career with personal values and a sense of purpose.",
          },
        ],
        Hints: [
          {
            data: "Start Small: Identify one task or project at work that you find meaningful and focus on how it aligns with your values. Gradually increase your involvement in similar tasks.",
          },
          {
            data: "Find Purpose in the Present: Reflect on aspects of your current role that might feel more aligned with your values, even if they’re small, like helping a colleague or improving team collaboration.",
          },
          {
            data: "Career Reflection: Set aside time weekly to reflect on your long-term goals and how your current job fits into them. If you’re struggling, consider seeking advice from a mentor or coach.",
          },
        ],
        Books:
          '"The Art of Happiness" by Dalai Lama – A guide to finding fulfillment and purpose through compassion and self-reflection, perfect for someone looking to reconnect with their deeper sense of meaning.',
        Podcasts:
          '"The Minimalists" – This podcast helps listeners focus on what truly matters by simplifying their lives, which can lead to greater purpose and impact.',
      },
    ],
  },
  {
    "Financial Health": [
      {
        Score: "Good",
        Trivia:
          "Did you know? Investing in a diversified portfolio across different asset classes can lower risk while still maintaining higher returns over time, helping you build a stronger financial safety net.",
        Apps: [
          {
            data: "INDmoney: For comprehensive financial tracking, including assets, liabilities, and investments across multiple categories like mutual funds, stocks, and fixed deposits.",
          },
          {
            data: "Zerodha Varsity: A resource-rich app to learn advanced investment strategies and deepen your understanding of the stock market.",
          },
          {
            data: "Groww: To explore new investment opportunities like real estate and international funds and manage your portfolio effectively.",
          },
        ],
        Hints: [
          {
            data: "Diversify Investments: Look beyond traditional investments like mutual funds and stocks. Consider real estate, global markets, or cryptocurrency to further diversify and optimize your portfolio.",
          },
          {
            data: "Maximize Tax Benefits: Take advantage of tax-saving instruments such as ELSS, NPS, and long-term investments in real estate to lower your taxable income and increase savings.",
          },
          {
            data: "Create Passive Income: Explore passive income streams through dividend stocks, rental properties, or peer-to-peer lending to grow your wealth effortlessly.",
          },
        ],
        Books:
          '"Let\'s Talk Money" by Monika Halan – This book provides practical advice on managing personal finances in India. It helps high performers fine-tune their strategies for investments, insurance, and creating financial security.',
        Podcasts:
          '"Paisa Vaisa" with Anupam Gupta – One of India\'s top financial podcasts, covering everything from wealth management to advanced investment strategies. Perfect for high performers looking to stay updated on market trends and advanced financial insights.',
      },
      {
        Score: "Average",
        Trivia:
          "Did you know? Automating your savings and investments can increase your likelihood of reaching financial goals by over 30%, as it removes the temptation to spend before saving.",
        Apps: [
          {
            data: "Walnut: A simple yet effective app for tracking daily expenses and managing budgets, helping you stay on top of your spending.",
          },
          {
            data: "ET Money: Ideal for setting financial goals, managing savings, and investing in mutual funds with easy tracking features.",
          },
          {
            data: "MoneyView: A budgeting app that also offers personal loans and helps with financial planning and expense tracking.",
          },
        ],
        Hints: [
          {
            data: "Automate Savings: Set up automatic monthly transfers to a savings account or investment plan. Even small, regular contributions can build up significantly over time.",
          },
          {
            data: "Debt Prioritization: Focus on paying off high-interest debts first, like credit card balances, to reduce financial strain and free up more money for savings.",
          },
          {
            data: "Emergency Fund: Start building a fund that covers at least 3-6 months of essential expenses. This will act as a safety net during financial emergencies.",
          },
        ],
        Books:
          '"The Richest Engineer" by Abhishek Kumar – This book explains how professionals can better manage their income, investments, and taxes, especially focusing on working individuals in India.',
        Podcasts:
          '"The Prudent Family" with Shweta Jain – Focuses on personal finance, budgeting, and family financial planning. It’s great for those at a medium level, looking for practical advice to further improve their financial standing.',
      },
      {
        Score: "Bad",
        Trivia:
          "Did you know? Tracking your spending can lead to a 15% reduction in unnecessary expenses, simply by making you more aware of where your money goes.",
        Apps: [
          {
            data: "PhonePe: A simple app to track daily expenses, pay bills, and manage basic transactions, helping to build awareness of where your money is going.",
          },
          {
            data: "Splitwise: Perfect for tracking shared expenses, especially with friends, roommates, or family, ensuring fair and clear expense sharing.",
          },
          {
            data: "FinArt: An expense tracker that automatically categorizes spending and provides insights to help you regain control over your finances.",
          },
          {
            data: "Spendee: Helps you create a basic budget, track your income and expenses, and start saving with easy-to-understand visuals.",
          },
        ],
        Hints: [
          {
            data: "Start a Basic Budget: Track your income and expenses for one month to identify areas where you can cut unnecessary spending. Begin with a simple rule, like the 50/30/20 budget (50% for needs, 30% for wants, 20% for savings).",
          },
          {
            data: "Reduce Non-Essentials: Cut back on non-essential expenses like dining out, subscriptions, or impulse purchases to free up cash for more important financial goals.",
          },
          {
            data: "Small Savings Habits: Start small by setting aside a fixed amount each week into a separate savings account. Over time, this will help build a savings habit.",
          },
        ],
        Books:
          '"Retire Rich: Invest Rs 40 a Day" by P.V. Subramanyam – This simple, straightforward book helps beginners understand the importance of small savings and how they can grow wealth over time.',
        Podcasts:
          '"Millennial Paisa" with Aakash Thakur – A beginner-friendly podcast that explains personal finance basics in India. Ideal for low performers who need guidance on how to get started with budgeting, saving, and investing',
      },
    ],
  },
];

export default initiativeCont_Genesis;
