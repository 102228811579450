// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#scroll-container::-webkit-scrollbar {
  width: 10px;
}
#scroll-container::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 20px;
  margin-left: 10px;
}
#scroll-container::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 10px;
}

.habit-curator-text {
  font-size: calc(60px + 1vw);
}

.gpt-question {
  font-size: calc(45px + 1vw);
}

.input-text::placeholder {
  color: gray;
}

.chat-container {
  padding-left: 200px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .chat-container {
    padding-left: 0px;
    margin-top: 90px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/Tesa.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;EAClB;AACF","sourcesContent":["#scroll-container::-webkit-scrollbar {\n  width: 10px;\n}\n#scroll-container::-webkit-scrollbar-track {\n  background: #ccc;\n  border-radius: 20px;\n  margin-left: 10px;\n}\n#scroll-container::-webkit-scrollbar-thumb {\n  background-color: #eee;\n  border-radius: 10px;\n}\n\n.habit-curator-text {\n  font-size: calc(60px + 1vw);\n}\n\n.gpt-question {\n  font-size: calc(45px + 1vw);\n}\n\n.input-text::placeholder {\n  color: gray;\n}\n\n.chat-container {\n  padding-left: 200px;\n}\n\n@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {\n  .chat-container {\n    padding-left: 0px;\n    margin-top: 90px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
