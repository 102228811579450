import React, { useState, useEffect } from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { IoIosArrowBack } from "react-icons/io";
import Physiological from "./Physiological";
import Financial from "./Financial";
import Spiritual from "./Spiritual";
import Tesa from "./Tesa";
import TesaV1 from "./TesaV1";
import { HOST_NAME } from "../const";
import Cookies from "js-cookie";
import axios from "axios";
import "./Home.css";
// import { useSearchParams } from "react-router-dom";

// import { v4 as uuidv4 } from "uuid";
// import { browserName } from "react-device-detect";
import { replace, useNavigate, useParams } from "react-router-dom";

const HomeV1 = () => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState(null);
  //   const [token, setToken] = useState();
  //   const [categoryScore, setCategoryScore] = useState();
  const { lastSegment } = useParams();

  // const [searchParams] = useSearchParams();
  // const lastSegment = searchParams.get("uuid");
  const navigate = useNavigate();

  useEffect(() => {
    const userLogin = async () => {
      // if (!Cookies.get("token")) {
      await axios
        .post(`${HOST_NAME}/report/create-user`, {
          uuid: lastSegment,
        })
        .then((res) => {
          console.log(res.data.token);
          Cookies.set("token", res.data.token);
          // setToken(res.data.token);
          // const createUser = async () => {
          //   await axios
          //     .post(`${TESA_HOST}/report/create-user`, {
          //       deviceId: uuidv4(),
          //       employee: lastSegment,
          //       deviceModel: browserName,
          //       location: "India",
          //     })
          //     .then((res) => {
          //       Cookies.set("deviceInfo", res.data.data.userData._id);
          //     })
          //     .catch((err) => console.log(err));
          // };
        })
        .catch((e) => {
          console.log(e);
        });

      await axios
        .post(`${HOST_NAME}/report/get-name`, {
          uuid: lastSegment,
        })
        .then((res) => {
          setName(res.data.name);
        })
        .catch((e) => {
          console.log(e);
        });

      await axios
        .post(`${HOST_NAME}/report/open-count`, {
          uuid: lastSegment,
        })
        .then((res) => {
          console.log("Count updated");
        })
        .catch((e) => {
          console.log(e);
        });

      // const fetchScore = async () => {
      //   console.log("hello");
      //   await axios
      //     .post(`${HOST_NAME}/report/score-uuid`, {
      //       uuid: lastSegment,
      //     })
      //     .then((res) => {
      //       setCategoryScore(res.data.data);
      //     })
      //     .catch((err) => console.log(err));
      // };
      // fetchScore();
    };
    userLogin();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("physiological");
  // const [showChat, setShowChat] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const handleChatClick = () => {
  //   setShowChat(!showChat);
  // };

  const renderContent = () => {
    switch (activeTab) {
      case "physiological":
        return <Physiological onTabClick={handleTabClick} />;
      case "financial":
        return <Financial onTabClick={handleTabClick} />;
      case "spiritual":
        return <Spiritual onTabClick={handleTabClick} />;
      case "tesa":
        return <TesaV1 onTabClick={handleTabClick} />;
      default:
        return <p>Welcome to the Home Page!</p>;
    }
  };

  if (visible) {
    return (
      <div className="flex">
        <div
          style={{ position: "fixed" }}
          className={`bg-white fixed top-0 left-0 h-screen border-r-2  transition-transform transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } md:relative md:translate-x-0 w-64 h-[91.8vh] md:h-full z-30`}
        >
          <div className="w-full flex-row-reverse flex md:hidden">
            <IoIosArrowBack
              className="mr-3 mt-5"
              size={30}
              color="#000"
              onClick={toggleSidebar}
            />
          </div>

          <div className="flex justify-center content-center m-4">
            <img
              className="w-3/5 md:w-10/12"
              src={require("../assets/unicom_logo.png")}
              alt="customer-logo"
            />
          </div>
          <ul>
            <li
              className={`p-3 mx-4 my-3 rounded-lg flex justify-center content-center cursor-pointer  ${
                activeTab === "physiological"
                  ? "bg-[rgba(40,96,181,0.2)]"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                handleTabClick("physiological");
                toggleSidebar();
              }}
            >
              Physiological Health
            </li>

            <li
              className={`p-3 mx-4 my-3 rounded-lg flex justify-center content-center cursor-pointer ${
                activeTab === "spiritual"
                  ? "bg-[rgba(40,96,181,0.2)]"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                handleTabClick("spiritual");
                toggleSidebar();
              }}
            >
              Spiritual Health
            </li>
            <li
              className={`p-3 mx-4 my-3 rounded-lg flex justify-center content-center cursor-pointer ${
                activeTab === "financial"
                  ? "bg-[rgba(40,96,181,0.2)]"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                handleTabClick("financial");
                toggleSidebar();
              }}
            >
              Financial Health
            </li>
            <li
              className={`p-1 md:p-2 mx-4 border-4 md:border-6 border-solid border-[#ADCDFD] rounded-lg flex justify-center content-center cursor-pointer ${
                activeTab === "tesa"
                  ? "bg-[rgba(40,96,181,0.2)]"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                handleTabClick("tesa");
                toggleSidebar();
              }}
            >
              <img
                className="w-5 h-5 md:w-7 md:h-7 relative -left-2"
                src={require("../assets/sparkles.png")}
              />
              <span>Growth Coach</span>
            </li>
          </ul>
          <div className="fixed bottom-6 w-full flex justify-center content-center">
            <img
              className="w-3/5 md:w-9/12"
              src={require("../assets/twp-logo.png")}
              alt="twp-logo"
            />
          </div>
        </div>

        {/* Main content */}
        <div
          id="main-container"
          className="flex-1 ml-36 h-[91.8vh] md:h-auto overflow-scroll md:overflow-auto p-4 bg-gradient-to-br from-[#FEFEFE] to-[#BDD8FF]"
        >
          <div className="md:hidden p-2 text-white" onClick={toggleSidebar}>
            <HiMenuAlt2 size={30} color="#000" />
          </div>

          {renderContent()}
        </div>
        {/* <button
        class="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-16 h-16 bg-black hover:bg-gray-700 m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900"
        type="button"
        aria-haspopup="dialog"
        aria-expanded="false"
        data-state="closed"
        onClick={() => handleChatClick()}
      >
        <svg
          xmlns=" http://www.w3.org/2000/svg"
          width="30"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="text-white block border-gray-200 align-middle"
        >
          <path
            d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z"
            class="border-gray-200"
          ></path>
        </svg>
      </button> */}
      </div>
    );
  } else {
    const letsgo = async () => {
      setVisible(true);
      // const path = window.location.pathname;
      // const lastSegment = path.split("/").filter(Boolean).pop();
      // Cookies.set("token", lastSegment);
      // await axios
      //   .post(`${HOST_NAME}/report/create-user`, {
      //     uuid: lastSegment,
      //   })
      //   .then((res) => {
      //     Cookies.set("token", res.data.token);
      //     setToken(res.data.token);
      //     const createUser = async () => {
      //       await axios
      //         .post(`${TESA_HOST}/report/create-user`, {
      //           deviceId: uuidv4(),
      //           employee: lastSegment,
      //           deviceModel: browserName,
      //           location: "India",
      //         })
      //         .then((res) => {
      //           Cookies.set("deviceInfo", res.data.data.userData._id);
      //         })
      //         .catch((err) => console.log(err));
      //     };
      //     createUser();
      //   })
      //   .catch((e) => console.log(e));
    };
    return (
      <div className="w-full h-screen bg-gradient-to-br from-[#FEFEFE] to-[#BDD8FF]">
        <div className="p-3 flex justify-between">
          <img
            alt="left-rocket"
            className="w-20 h-16"
            src={require("../assets/left-rocket.png")}
          />
          <img
            alt="left-rocket"
            className="w-28 h-16"
            src={require("../assets/logo.png")}
          />
        </div>
        <img
          alt="stroke1"
          className="absolute w-20 h-20 rotate-0 md:rotate-12 right-3 mt-5 md:w-52 md:h-52 md:right-[27%]"
          src={require("../assets/stroke2.png")}
        />
        <img
          alt="stroke1"
          className="absolute w-20 h-20 rotate-0 md:rotate-12 right-3 mt-5 md:w-32 md:h-30 md:right-[20%]"
          src={require("../assets/rocket.png")}
        />
        <div className=" w-full flex justify-center items-center flex-col">
          <div className="w-[85%] md:w-[40%] h-[75%] md:h-[60vh] mt-7 pt-5 pb-7 bg-white rounded-lg flex justify-center items-center flex-col z-30">
            <span className="text-[#2961B6] text-3xl">
              Hi {name !== null ? name : "!"} !
            </span>
            <span className="mt-3 text-center">
              Welcome to your Thrive Report
            </span>
            <span className="w-[85%] mt-12 text-center text-[#31383F]">
              This is your guide to help you understand current status across
              health levers alongside T.E.S.A. , your AI Growth Partner. You'll
              receive personalized recommendations and resources to take mindful
              steps toward becoming your holistic self.
            </span>
            <div
              onClick={() => letsgo()}
              className="bg-[#2961B6] w-[50%] h-[40px] rounded-lg flex justify-center items-center cursor-pointer mt-12"
            >
              <span className="text-[#fff]">Let's Go!</span>
            </div>
          </div>
          <img
            alt="stroke1"
            className="absolute w-20 md:w-52 h-20 md:h-52 scale-x-[-1] rotate-45 left-3 md:left-[25%] bottom-28 md:bottom-[15%]"
            src={require("../assets/stroke2.png")}
          />
        </div>
      </div>
    );
  }
};

export default HomeV1;
