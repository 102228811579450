const initiativeCont_Unicom = [
  {
    "Physiological Health": [
      {
        Score: "Good",
        Trivia:
          "Walking briskly for just 30 minutes can reduce symptoms of anxiety and depression by 47%. Your body burns more calories digesting protein than fats or carbs—this is called the thermic effect of food. The longest anyone has held a plank is 9 hours, 30 minutes, and 1 second!",
        Apps: [
          {
            data: "Strava: Perfect for tracking more advanced fitness activities like running, cycling, or swimming and joining challenges to push yourself further.",
          },
          {
            data: "MyFitnessPal: For detailed tracking of your nutrition and macros, helping you fine-tune your diet for peak performance.",
          },
          {
            data: "Cure.fit (Cult.fit): Offers a wide variety of high-intensity workout classes, yoga, and strength training sessions to maintain and enhance your fitness levels.",
          },
          {
            data: "Google Fit : Google Fit is a health and fitness tracking app that monitors your activity levels and step counts.",
          },
          {
            data: "Sleep Cycle (Free Tier): Basic sleep tracking for better rest.",
          },
        ],
        Hints: [
          {
            data: "Use the 80/20 rule: Focus 80% on consistent habits and 20% on optimizing performance (e.g., advanced nutrition or workout techniques).",
          },
          {
            data: "Drink a glass of water immediately after waking up to kickstart metabolism and hydration.",
          },
          {
            data: "Sneak activity into your day: Take the stairs, stretch during breaks, or do bodyweight exercises while watching TV.",
          },
          {
            data: "Use the Pomodoro Technique with movement: Work for 25 minutes, then do a 5-minute workout.",
          },
        ],
        Books: [
          {
            data: "The 4-Hour Body by Tim Ferriss: Hacks to improve health and fitness efficiently.",
          },
          {
            data: "Born to Run by Christopher McDougall: Insights into endurance and optimal physicality.",
          },
          {
            data: "'Atomic Habits' by James Clear – A practical guide on building and sustaining healthy habits, making it easier to maintain long-term success in nutrition, exercise, and rest.",
          },
        ],

        Podcasts: [
          {
            data: "Huberman Lab Podcast: Scientific insights for maximizing performance. Mind Pump: Raw Fitness Truth: Advanced fitness strategies.",
          },
        ],
      },
      {
        Score: "Average",
        Trivia:
          "Walking briskly for just 30 minutes can reduce symptoms of anxiety and depression by 47%. Your body burns more calories digesting protein than fats or carbs—this is called the thermic effect of food. The longest anyone has held a plank is 9 hours, 30 minutes, and 1 second!",
        Apps: [
          {
            data: "Google Fit: Tracks daily activity and sets step goals.",
          },
          {
            data: "30 Day Fitness Challenge: Structured plans to maintain consistency.",
          },
          {
            data: "MyPlate by Livestrong: Free nutrition and fitness tracker.",
          },
          {
            data: "FitOn: Offers free workout plans, including cardio, strength, and yoga sessions, helping you gradually improve your fitness level.",
          },
          {
            data: "7 Minute Workout: A quick and effective app that allows you to incorporate short bursts of exercise into your daily routine, perfect for busy schedules.",
          },
          {
            data: "WaterMinder: An app that helps track your daily water intake, ensuring proper hydration, which is essential for overall health and energy levels.",
          },
        ],
        Hints: [
          {
            data: "Add variety to your workouts to prevent plateaus (e.g., alternate cardio and strength training).",
          },
          {
            data: "Drink a glass of water immediately after waking up to kickstart metabolism and hydration.",
          },
          {
            data: "Sneak activity into your day: Take the stairs, stretch during breaks, or do bodyweight exercises while watching TV.",
          },
          {
            data: "Use the Pomodoro Technique with movement: Work for 25 minutes, then do a 5-minute workout.",
          },
        ],
        Books: [
          {
            data: "Why We Sleep by Matthew Walker: Understand the importance of sleep.",
          },
          {
            data: "You Are Your Own Gym by Mark Lauren: Bodyweight exercises for at-home workouts.",
          },
          {
            data: "'Feel Better in 5' by Dr. Rangan Chatterjee – This book provides simple, five-minute daily activities that can improve your physical and mental well-being.",
          },
        ],

        Podcasts: [
          {
            data: "Found My Fitness: Science-backed insights into nutrition and exercise.",
          },
          {
            data: "Optimal Health Daily: Bite-sized health tips to keep improving",
          },
        ],
      },
      {
        Score: "Bad",
        Trivia:
          "Walking briskly for just 30 minutes can reduce symptoms of anxiety and depression by 47%. Your body burns more calories digesting protein than fats or carbs—this is called the thermic effect of food. The longest anyone has held a plank is 9 hours, 30 minutes, and 1 second!",
        Apps: [
          {
            data: "Pacer: A simple app that tracks your steps and encourages you to walk more, perfect for building a habit of daily movement.",
          },
          {
            data: "Simple Habit Tracker: Helps you develop healthy habits, like drinking more water or getting enough sleep, with easy-to-follow goals.",
          },
          {
            data: "Sleep Cycle: Monitors your sleep patterns and provides insights on how to improve the quality of your rest, crucial for overall health improvement.",
          },
          {
            data: "FitOn: Free workouts for beginners to kickstart fitness habits.",
          },
          {
            data: "Water Drink Reminder: Encourages hydration through timely reminders.",
          },
          {
            data: "Sleep Cycle (Free Tier): Basic sleep tracking for better rest.",
          },
        ],
        Hints: [
          {
            data: "Start small—5 minutes of stretching daily can create momentum for consistent exercise.",
          },
          {
            data: "Drink a glass of water immediately after waking up to kickstart metabolism and hydration.",
          },
          {
            data: "Sneak activity into your day: Take the stairs, stretch during breaks, or do bodyweight exercises while watching TV.",
          },
          {
            data: "Use the Pomodoro Technique with movement: Work for 25 minutes, then do a 5-minute workout.",
          },
        ],
        Books: [
          {
            data: "Atomic Habits by James Clear: Learn to build small habits that compound over time.",
          },
          {
            data: "The Fitness Mindset by Brian Keane: Simple fitness and mindset tips for beginners.",
          },
          {
            data: "Tiny Habits by BJ Fogg – This book teaches how to start with small, manageable changes that can lead to bigger transformations over time, perfect for someone looking to make small but impactful improvements.",
          },
        ],

        Podcasts: [
          {
            data: "The Model Health Show: Beginner-friendly tips for improving fitness, sleep, and nutrition.",
          },
          {
            data: "The Health Code: Fun and easy lifestyle hacks to build healthy habits. ",
          },
        ],
      },
    ],
  },
  {
    "Spiritual Health": [
      {
        Score: "Good",
        Trivia:
          "Studies show that practicing gratitude can increase happiness by 25%. The term 'Ikigai' is a Japanese concept meaning 'reason for being,' and it's linked to longer, happier lives. The Dalai Lama starts his day with the thought: “Today, I am fortunate to be alive. I have a precious human life, and I am not going to waste it.”",
        Apps: [
          {
            data: "Coach.me: A goal-setting and habit-building app that helps you stay aligned with your purpose while also mentoring others on their journey.",
          },
          {
            data: "Asana: Perfect for managing purpose-driven projects, allowing you to track progress on initiatives that align with your values and long-term goals.",
          },
          {
            data: "LinkedIn Learning: Offers courses on leadership, personal growth, and purpose-driven work, helping you take your spiritual and career alignment to the next level.",
          },
          {
            data: "Insight Timer (Free Advanced Features): Explore live sessions and advanced meditations.",
          },
          {
            data: "Jour (Free Journaling): Reflect on purpose and larger life goals.",
          },
        ],
        Hints: [
          {
            data: "Create a gratitude jar: Write one thing you're grateful for every day and review it monthly.",
          },
          {
            data: "Use breathing techniques like 4-7-8 breathing to instantly reduce stress and center yourself.",
          },
          {
            data: "Connect with nature: Spend time outdoors weekly for a natural boost in mindfulness and purpose alignment.",
          },
        ],
        Books: [
          {
            data: "Meditations by Marcus Aurelius: Stoic philosophy on living a virtuous life.",
          },
          {
            data: "The Bhagavad Gita (Free Versions): A spiritual text exploring purpose and dharma.",
          },
        ],

        Podcasts: [
          {
            data: "The Minimalists Podcast: Insights on simplifying life and focusing on purpose.",
          },
          {
            data: "Buddhist Geeks: Discussions on advanced spirituality and mindfulness.",
          },
          {
            data: '"The School of Greatness" with Lewis Howes – This podcast features inspiring stories from people who have found their purpose and made a meaningful impact in their communities and industries.',
          },
        ],
      },
      {
        Score: "Average",
        Trivia:
          "Studies show that practicing gratitude can increase happiness by 25%. The term 'Ikigai' is a Japanese concept meaning 'reason for being,' and it's linked to longer, happier lives. The Dalai Lama starts his day with the thought: “Today, I am fortunate to be alive. I have a precious human life, and I am not going to waste it.”",
        Apps: [
          {
            data: "Notion: Ideal for organizing your career goals, vision boards, and tracking tasks that align with your personal and professional values.",
          },
          {
            data: "Trello: Helps you manage and prioritize projects that are meaningful to you, ensuring your daily tasks align with your bigger life and career goals.",
          },
          {
            data: "Headspace for Work: Although primarily known for mindfulness, it offers tools for career reflection and aligning your work with your personal purpose.",
          },
          {
            data: "Stop, Breathe & Think: Free mood check-ins and mindfulness practices.",
          },
          {
            data: "Grateful: Journaling app to cultivate gratitude and positivity.",
          },
        ],
        Hints: [
          {
            data: "Create a gratitude jar: Write one thing you're grateful for every day and review it monthly.",
          },
          {
            data: "Use breathing techniques like 4-7-8 breathing to instantly reduce stress and center yourself.",
          },
          {
            data: "Connect with nature: Spend time outdoors weekly for a natural boost in mindfulness and purpose alignment.",
          },
        ],
        Books: [
          {
            data: "The Untethered Soul by Michael A. Singer: Exploring inner peace and alignment.",
          },
          {
            data: "Man’s Search for Meaning by Viktor E. Frankl: Insights on purpose from a Holocaust survivor.",
          },
        ],

        Podcasts: [
          {
            data: "The Happiness Lab: Science-based happiness strategies.",
          },
          {
            data: "Untangle: Real-life mindfulness stories and techniques.",
          },
          {
            data: "On Purpose with Jay Shetty: This podcast focuses on finding your purpose and connecting your daily actions to a larger mission. It features insights and conversations on living a life of meaning and purpose.",
          },
        ],
      },
      {
        Score: "Bad",
        Trivia:
          "Studies show that practicing gratitude can increase happiness by 25%. The term 'Ikigai' is a Japanese concept meaning 'reason for being,' and it's linked to longer, happier lives. The Dalai Lama starts his day with the thought: “Today, I am fortunate to be alive. I have a precious human life, and I am not going to waste it.”",
        Apps: [
          {
            data: "Daylio: A simple journaling app that helps track your mood and activities, allowing you to reflect on what brings meaning and fulfillment to your day.",
          },
          {
            data: "Penzu: A digital diary for reflecting on your values, work experiences, and identifying small ways to align your daily activities with your larger purpose.",
          },
          {
            data: "Todoist: Helps in setting small, achievable goals that can slowly build towards aligning your career with personal values and a sense of purpose.",
          },
          {
            data: "Insight Timer: Free guided meditations for beginners.",
          },
          {
            data: "Smiling Mind: Mindfulness exercises designed for stress reduction.",
          },
        ],
        Hints: [
          {
            data: "Begin with 2-minute mindfulness exercises to develop the habit of staying present.",
          },
          {
            data: "Create a gratitude jar: Write one thing you're grateful for every day and review it monthly.",
          },
          {
            data: "Use breathing techniques like 4-7-8 breathing to instantly reduce stress and center yourself.",
          },
          {
            data: "Connect with nature: Spend time outdoors weekly for a natural boost in mindfulness and purpose alignment.",
          },
        ],
        Books: [
          {
            data: "The Power of Now by Eckhart Tolle: Living fully in the present moment.",
          },
          {
            data: "The Four Agreements by Don Miguel Ruiz: A practical guide to personal freedom.",
          },
        ],

        Podcasts: [
          {
            data: "The Daily Stoic Podcast: Bite-sized stoic wisdom for building resilience.",
          },
          {
            data: "On Being with Krista Tippett: Explores foundational questions of purpose and meaning.",
          },
        ],
      },
    ],
  },
  {
    "Financial Health": [
      {
        Score: "Good",
        Trivia:
          "The first credit card, Diners Club, was introduced in 1950 and only worked in select restaurants. Compounding is so powerful that Albert Einstein allegedly called it the “eighth wonder of the world.” In Japan, many people save by using a practice called kakeibo, a mindful budgeting journal.",
        Apps: [
          {
            data: "Personal Capital: Tracks net worth and provides investment tools.",
          },
          {
            data: "Robinhood (Free Tier): Commission-free trading for stocks and ETFs.",
          },
          {
            data: "INDmoney: For comprehensive financial tracking, including assets, liabilities, and investments across multiple categories like mutual funds, stocks, and fixed deposits.",
          },
          {
            data: "Zerodha Varsity: A resource-rich app to learn advanced investment strategies and deepen your understanding of the stock market.",
          },
          {
            data: "Groww: To explore new investment opportunities like real estate and international funds and manage your portfolio effectively.",
          },
        ],
        Hints: [
          {
            data: "Diversify your income streams with side hustles or passive investments.",
          },
          {
            data: "Use the 50/30/20 Rule for budgeting: 50% for needs, 30% for wants, and 20% for savings.",
          },
          {
            data: 'Set a "no-spend day" weekly to build awareness around unnecessary expenses.',
          },
          {
            data: "Look for free financial literacy courses on platforms like Khan Academy or Coursera.",
          },
        ],
        Books: [
          {
            data: "Let's Talk Money by Monika Halan – This book provides practical advice on managing personal finances in India. It helps high performers fine-tune their strategies for investments, insurance, and creating financial security.",
          },
          {
            data: "The Psychology of Money by Morgan Housel: Understanding emotional factors in financial decisions.",
          },
          {
            data: "The Millionaire Next Door by Thomas J. Stanley: Habits and strategies of financially successful people.",
          },
        ],

        Podcasts: [
          {
            data: "Paisa Vaisa with Anupam Gupta – One of India's top financial podcasts, covering everything from wealth management to advanced investment strategies. Perfect for high performers looking to stay updated on market trends and advanced financial insights.",
          },
          {
            data: "We Study Billionaires: Learn from the financial strategies of the wealthy.",
          },
          {
            data: "Afford Anything: Focuses on financial independence and lifestyle design.",
          },
        ],
      },
      {
        Score: "Average",
        Trivia:
          "The first credit card, Diners Club, was introduced in 1950 and only worked in select restaurants. Compounding is so powerful that Albert Einstein allegedly called it the “eighth wonder of the world.” In Japan, many people save by using a practice called kakeibo, a mindful budgeting journal.",
        Apps: [
          {
            data: "Walnut: A simple yet effective app for tracking daily expenses and managing budgets, helping you stay on top of your spending.",
          },
          {
            data: "ET Money: Ideal for setting financial goals, managing savings, and investing in mutual funds with easy tracking features.",
          },
          {
            data: "MoneyView: A budgeting app that also offers personal loans and helps with financial planning and expense tracking.",
          },
          {
            data: "PocketGuard: Manages cash flow and helps track financial goals.",
          },
          {
            data: "Acorns (Free Tier): Automates saving and micro-investing.",
          },
        ],
        Hints: [
          {
            data: "Automate your savings to build financial discipline effortlessly.",
          },
          {
            data: "Use the 50/30/20 Rule for budgeting: 50% for needs, 30% for wants, and 20% for savings.",
          },
          {
            data: 'Set a "no-spend day" weekly to build awareness around unnecessary expenses.',
          },
          {
            data: "Look for free financial literacy courses on platforms like Khan Academy or Coursera.",
          },
        ],
        Books: [
          {
            data: '"The Richest Engineer" by Abhishek Kumar – This book explains how professionals can better manage their income, investments, and taxes, especially focusing on working individuals in India.',
          },
          {
            data: "Rich Dad Poor Dad by Robert Kiyosaki: Fundamentals of financial literacy.",
          },
          {
            data: "The Simple Path to Wealth by JL Collins: Easy steps to grow your savings.",
          },
        ],

        Podcasts: [
          {
            data: '"The Prudent Family" with Shweta Jain – Focuses on personal finance, budgeting, and family financial planning. It’s great for those at a medium level, looking for practical advice to further improve their financial standing.',
          },
          {
            data: "ChooseFI: Tips for building savings and financial independence.",
          },
          {
            data: "The Dave Ramsey Show: Strategies for reducing debt and creating wealth.",
          },
        ],
      },
      {
        Score: "Bad",
        Trivia:
          "The first credit card, Diners Club, was introduced in 1950 and only worked in select restaurants. Compounding is so powerful that Albert Einstein allegedly called it the “eighth wonder of the world.” In Japan, many people save by using a practice called kakeibo, a mindful budgeting journal.",
        Apps: [
          {
            data: "Mint: Easy-to-use tool for tracking income and expenses.",
          },
          {
            data: "Goodbudget: A simple envelope budgeting system.",
          },
          {
            data: "PhonePe: A simple app to track daily expenses, pay bills, and manage basic transactions, helping to build awareness of where your money is going.",
          },
          {
            data: "Splitwise: Perfect for tracking shared expenses, especially with friends, roommates, or family, ensuring fair and clear expense sharing.",
          },
          {
            data: "FinArt: An expense tracker that automatically categorizes spending and provides insights to help you regain control over your finances.",
          },
          {
            data: "Spendee: Helps you create a basic budget, track your income and expenses, and start saving with easy-to-understand visuals.",
          },
        ],
        Hints: [
          {
            data: "Start tracking every expense for one week to understand where your money is going.",
          },
          {
            data: "Use the 50/30/20 Rule for budgeting: 50% for needs, 30% for wants, and 20% for savings.",
          },
          {
            data: 'Set a "no-spend day" weekly to build awareness around unnecessary expenses.',
          },
          {
            data: "Look for free financial literacy courses on platforms like Khan Academy or Coursera.",
          },
        ],
        Books: [
          {
            data: "Retire Rich: Invest Rs 40 a Day by P.V. Subramanyam – This simple, straightforward book helps beginners understand the importance of small savings and how they can grow wealth over time.",
          },
          {
            data: "The Total Money Makeover by Dave Ramsey: Simple steps to get out of debt.",
          },
          {
            data: "Broke Millennial by Erin Lowry: Basic financial advice for beginners.",
          },
        ],

        Podcasts: [
          {
            data: '"Millennial Paisa" with Aakash Thakur – A beginner-friendly podcast that explains personal finance basics in India. Ideal for low performers who need guidance on how to get started with budgeting, saving, and investing',
          },
          {
            data: "How to Money: Beginner-friendly advice for saving and budgeting.",
          },
          {
            data: "The Clark Howard Podcast: Practical money-saving strategies.",
          },
        ],
      },
    ],
  },
];

export default initiativeCont_Unicom;
