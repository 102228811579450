import React, { useState, useEffect, useRef } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import { HOST_NAME } from "../const";
import ProgressBar from "@ramonak/react-progress-bar";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./Components.css";

Chart.register(...registerables);

const FinancialScore = ({ onTabClick }) => {
  const [categoryScore, setCategoryScore] = useState(null);
  const [leverScore, setLeverScore] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [slides, setSlides] = useState([]);

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const chartRef = useRef(null);
  const [gradientColor, setGradientColor] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    const lastSegment = path.split("/").filter(Boolean).pop();

    const chart = chartRef.current;
    if (chart) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);

      gradient.addColorStop(0, "rgba(40, 96, 181, 0.3)");
      gradient.addColorStop(1, "#2860B5");

      setGradientColor(gradient);
    }

    const fetchScore = async () => {
      await axios
        .post(`${HOST_NAME}/report/score-uuid`, {
          uuid: lastSegment,
        })
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const financialHealth = response.data.data[2]["Financial Health"];
            if (financialHealth && financialHealth.categories) {
              setCategoryScore(financialHealth.categories);
              setLeverScore(financialHealth.score);
            }
          }
        })
        .catch((err) => console.log(err));
    };

    const fetchResponse = async () => {
      await axios
        .post(`${HOST_NAME}/report/get-ind-res-data`, {
          uuid: lastSegment,
        })
        .then((res) => {
          setResponseData(res.data.data.financial);
        })
        .catch((err) => console.log(err));
    };

    const getOrg = async () => {
      await axios
        .post(`${HOST_NAME}/report/get-ind-rec`, {
          uuid: lastSegment,
        })
        .then((res) => {
          console.log(res.data.data);

          let recommendation = res.data.data;
          setSlides([
            <p className="slide">
              {categoryScore === null
                ? ""
                : categoryScore[0]["Status quo"] >= 0 &&
                  categoryScore[0]["Status quo"] <= 30
                ? recommendation[5].finance.low
                : categoryScore[0]["Status quo"] >= 31 &&
                  categoryScore[0]["Status quo"] <= 70
                ? recommendation[5].finance.medium
                : recommendation[5].finance.high}
            </p>,
          ]);
        })
        .catch((e) => console.log(e));
    };

    getOrg();

    fetchScore();
    fetchResponse();
  }, [chartRef, slides, responseData]);

  const colorData = {
    1: "orange",
    2: "orange",
    3: "gray",
    4: "green",
    5: "green",
  };

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    plugins: {
      datalabels: {
        display: function (context) {
          return context.dataset.backgroundColor === "#2860B5";
        },
        color: "rgba(255,255,255,0.9)",
        align: "top",
        formatter: function (value) {
          return value ? Math.ceil(parseInt(value)).toString() + "%" : "";
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        id="overview-cont"
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          marginTop: "50px",
          padding: "30px",
          borderRadius: "12px",
          backgroundColor: "rgba(255,255,255,0.5)",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            width: "87%",
            fontWeight: "600",
            // textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          <span style={{ fontSize: "17px" }}>Financial Health</span> examines
          employees' financial awareness of the status quo and efforts toward
          economic stability.
        </p>
        <div
          className="cont1"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div id="doughnut" style={{ width: "180px", height: "180px" }}>
            <p style={{ position: "relative", left: "120px", top: "10px" }}>
              *{" "}
              {leverScore >= 0 && leverScore <= 30 && leverScore !== null
                ? "Struggling"
                : leverScore >= 30 && leverScore <= 70 && leverScore !== null
                ? "Progressing"
                : "Excelling"}
            </p>
            <Doughnut
              ref={chartRef}
              data={{
                datasets: [
                  {
                    data:
                      leverScore !== null
                        ? [leverScore, 100 - leverScore]
                        : [0, 100],
                    backgroundColor: [gradientColor, "rgba(0,0,0,0.1)"],
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                rotation: 0,
                circumference: 360,
                cutout: "75%",
                maintainAspectRatio: true,
                responsive: true,
              }}
            />
            <p
              style={{
                fontWeight: "400",
                fontSize: "18px",
                color: "#2961B6",
                marginTop: "-110px",
                marginLeft: "70px",
              }}
            >
              Score <br /> {Math.ceil(leverScore)}%
            </p>
          </div>
          <div
            className={{
              maxWidth: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              id="bar"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {categoryScore &&
              Array.isArray(categoryScore) &&
              categoryScore.length > 0 ? (
                categoryScore.map((score) => (
                  <Bar
                    style={{
                      height: "250px",
                      width: "250px",
                    }}
                    plugins={[ChartDataLabels]}
                    data={{
                      labels: Object.keys(score),
                      datasets: [
                        {
                          backgroundColor: "#2860B5",
                          data: [Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 10,
                            topRight: 10,
                            bottomLeft: 10,
                            bottomRight: 10,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                        {
                          backgroundColor: "rgba(0,0,0,0)",
                          data: [100 - Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 10,
                            topRight: 10,
                            bottomLeft: 0,
                            bottomRight: 0,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                      ],
                    }}
                    options={options}
                  />
                ))
              ) : (
                <div>No data available</div>
              )}
            </div>
          </div>
        </div>
        <div
          id="cont2"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div id="statement">
            <p className="font-bold text-xl">Status Quo</p>
            <p>I regularly monitor my finances</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[0])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[0]) : 0
              }
            />
            <p>I handle my finances (earn vs spend) well</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[1])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[1]) : 0
              }
            />
            <p>My current salary aligns well with my financial needs</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[2])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[2]) : 0
              }
            />
            <p>I am actively contributing to my retirement savings</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[3])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[3]) : 0
              }
            />
            <p>I contribute to emergency funds along with my savings</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[4])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[4]) : 0
              }
            />
            <p>I have loans & debts to payback</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[5])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[5]) : 0
              }
            />
            <p>
              I do not face any struggle when repaying my loans, debts, or EMIs
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[6])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[6]) : 0
              }
            />
            <p>I am the single source of income for my family</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[7])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[7]) : 0
              }
            />
            {/* <p>My organization supports me during times of need</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[8])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[8]) : 0
              }
            /> */}
            {responseData && responseData[9] !== null ? (
              <>
                {" "}
                <p>
                  There are adequate schemes available here for my financial
                  support, safety & well-being
                </p>
                <ProgressBar
                  className="w-11/12 m-2"
                  height="12px"
                  labelSize="10px"
                  bgColor={
                    responseData !== null
                      ? colorData[parseInt(responseData[9])]
                      : 1
                  }
                  completed={
                    responseData !== null ? 20 * parseInt(responseData[9]) : 0
                  }
                />
              </>
            ) : (
              <></>
            )}
            {/* <p>I am aware of other sources to save my finances</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              labelSize="10px"
              bgColor={
                responseData !== null
                  ? colorData[parseInt(responseData[10])]
                  : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[10]) : 0
              }
            /> */}
          </div>
          <div
            className="slider-container"
            id="recommendation"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="slider"
              style={{
                width: "100%",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`slider-item ${
                    index === currentSlide ? "active" : ""
                  }`}
                  style={{
                    transform: `translateX(-${currentSlide * 100}%)`,
                  }}
                >
                  {slide}
                  {/* <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {slides.map((_, index) => (
                      <div
                        key={index}
                        onClick={() => handleDotClick(index)}
                        style={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "50%",
                          margin: "0 5px",
                          cursor: "pointer",
                          backgroundColor:
                            index === currentSlide ? "#555" : "#ccc",
                          transition: "background-color 0.3s ease",
                        }}
                      />
                    ))}
                  </div> */}
                </div>
              ))}
            </div>
            {/* <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={goToPreviousSlide}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                  fontSize: "30px",
                  // border: "1px solid #000",
                  padding: "5px",
                  // borderRadius: "10px",
                  color: "#555",
                  height: "50px",
                  width: "50px",
                }}
              >
                {"<"}
              </button>
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "30px",
                  // border: "1px solid #000",
                  padding: "5px",
                  color: "#555",
                  // borderRadius: "10px",
                  height: "50px",
                  width: "50px",
                }}
                onClick={goToNextSlide}
              >
                {">"}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialScore;
