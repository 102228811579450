import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Invalid from "./screens/Invalid";
import HomeV1 from "./screens/HomeV1";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Invalid />} />
        <Route path="/:lastSegment" element={<HomeV1 />} />
      </Routes>
    </Router>
    // <Home />
  );
};

export default App;
