// export const TESA_HOST = "http://localhost:3900";
// export const HOST_NAME = "http://localhost:4000";
export const HOST_NAME = "https://api.thrivingworkplace.in";
export const TESA_HOST = "https://apicuration.aspirenow.in";

// [
//   {
//     end: 6,
//     name: "Physiological Health",
//     start: 1,
//     category: [
//       { end: 2, name: "Wellbeing", start: 1 },
//       { end: 3, name: "Movement", start: 3 },
//       { end: 6, name: "Energy", start: 4 },
//     ],
//   },
//   {
//     end: 12,
//     name: "Spiritual Health",
//     start: 7,
//     category: [
//       { end: 8, name: "Purpose", start: 7 },
//       { end: 12, name: "Impact", start: 9 },
//     ],
//   },
//   {
//     end: 18,
//     name: "Financial Health",
//     start: 13,
//     category: [{ end: 18, name: "Status quo", start: 13 }],
//   },
//   {
//     end: 26,
//     name: "Intellectual Health",
//     start: 19,
//     category: [
//       { end: 20, name: "Learning", start: 19 },
//       { end: 22, name: "Innovation", start: 21 },
//       { end: 26, name: "Fitment", start: 23 },
//     ],
//   },
//   {
//     end: 35,
//     name: "Career Health",
//     start: 27,
//     category: [
//       { end: 28, name: "Recognition", start: 27 },
//       { end: 32, name: "Mentorship", start: 29 },
//       { end: 35, name: "Growth", start: 33 },
//     ],
//   },
//   {
//     end: 47,
//     name: "Business Health",
//     start: 36,
//     category: [
//       { end: 37, name: "Leadership", start: 36 },
//       { end: 40, name: "Brand", start: 38 },
//       { end: 43, name: "Benefits", start: 41 },
//       { end: 46, name: "Competence", start: 44 },
//     ],
//   },
//   {
//     end: 57,
//     name: "Cultural Health",
//     start: 48,
//     category: [
//       { end: 50, name: "Values", start: 48 },
//       { end: 52, name: "Practices", start: 51 },
//       { end: 56, name: "Ethics", start: 53 },
//       { end: 57, name: "Places", start: 57 },
//     ],
//   },
//   {
//     end: 62,
//     name: "Social Health",
//     start: 58,
//     category: [
//       { end: 59, name: "Collaboration", start: 58 },
//       { end: 62, name: "Community", start: 60 },
//     ],
//   },
//   {
//     end: 70,
//     name: "Mental Health",
//     start: 63,
//     category: [
//       { end: 64, name: "Motivation", start: 63 },
//       { end: 68, name: "Support", start: 65 },
//       { end: 70, name: "Expression", start: 69 },
//     ],
//   },
//   {
//     end: 74,
//     name: "Digital and Tech Health",
//     start: 71,
//     category: [
//       { end: 72, name: "Adeptness", start: 71 },
//       { end: 74, name: "Availability and Access", start: 73 },
//     ],
//   },
// ];
